onDomReady(function () {
   TopicIndexPage.initialize();
});

// eslint-disable-next-line no-var
export var TopicIndexPage = (window.TopicIndexPage = {
   initialize: function () {
      $$('.toggleAllGuidesView').each(function (el) {
         el.addEvent('click', function (ev) {
            ev.stop();
            let pContainer = el.getParent('.blurbImageLinkCluster');
            let images = pContainer.getElements('.blurbImageDataContainer');
            let visible = el.retrieve('visible', false);

            if (visible) {
               el.store('visible', false);
               el.set('html', el.get('data-moreText'));
               images.each(function (guide, index) {
                  if (index >= el.get('data-maxVisible').toInt()) {
                     guide.addClass('hidden');
                  }
               });
            } else {
               el.set('html', el.get('data-lessText'));
               el.store('visible', true);
               images.each(function (guide) {
                  guide.removeClass('hidden');
               });
            }
         });
      });
   },
});
